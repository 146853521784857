import * as React from "react";
import axios from "axios";
import { fadeInUpStyle } from "../../styles/style";
import { Link } from "gatsby";

class PostsList extends React.Component {
    constructor(props) {
        super(props);
        this.updateData = this.updateData.bind(this);
        this.state = {
            url: 'https://erp.manutenpul.it/blog/api/posts',
            data: [],
            prev: null,
            next: null
        };
    }

    updateUrl(url) {
        this.setState({ url: url });
    }

    updateData(props) {
        axios.get(
            this.state.url,
            {
                headers: {
                    // "X-Requested-With": "XMLHttpRequest",
                    // "Access-Control-Allow-Origin": "*",
                    "Content-Type": "multipart/form-data;",
                },
                responseType: 'json',
            }).then((response) => {
                this.setState({
                    data: response.data.results,
                    next: response.data.next,
                    prev: response.data.previous
                })
            });
    }

    showPrev() {
        if (this.state.prev) {
            return (
                <li><button onClick={() => this.updateUrl(this.state.prev)}><span className="material-icons" >west</span></button></li>
            );
        }
    }

    showNext() {
        if (this.state.next) {
            return (
                <li><button onClick={() => this.updateUrl(this.state.next)}><span className="material-icons" >east</span></button></li>
            );
        }
    }

    componentDidMount() {
        this.updateData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.url !== this.state.url) {
            this.updateData();
        }
        if (this.props.category !== prevProps.category) {
            if (this.props.category) {
                this.updateUrl('https://erp.manutenpul.it/blog/api/posts?category=' + this.props.category);
            }else{
                this.updateUrl('https://erp.manutenpul.it/blog/api/posts');
            }
        }
    }

    render() {
        const { data } = this.state;
        return (
            <div className="col-xl-8 col-lg-7">
                <div className="left-side-wrapper">
                    {data.map((post) => (
                        <div className="single-blog blog-style-2 mb-60 wow fadeInUp" data-wow-delay=".2s" style={fadeInUpStyle}>
                            <div className="blog-img">
                                <Link to={"/blog/post?id=" + post.id} className="page-scroll"><img src={post.imagen_principal} alt="example1" /></Link>
                            </div>
                            <div className="blog-content text-left">
                                <h4><Link to={"/blog/post?id=" + post.id} className="page-scroll">&nbsp;{post.titulo}</Link></h4>
                                <p>&nbsp;&nbsp;{post.introduccion}</p>
                                <Link to={"/blog/post?id=" + post.id} className="theme-btn">Leggi più<span className="material-icons">open_in_new</span></Link>
                                <i class="lni lni-angle-double-right"></i>
                            </div>
                        </div>
                    ))}
                    <div className="pagination">
                        <ul className="d-flex justify-content-center align-items-center">
                            {this.showPrev()}
                            {this.showNext()}
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}

export default PostsList;