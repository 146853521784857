import * as React from "react";
import { pageStyles } from "../styles/style";
import Footer from "../components/Footer";
import DownbarS from "../components/down_footer_s";
import Navbar from "../components/Navbar";
import Banner from "../components/Banner";
import { Helmet } from "react-helmet";
import "../styles/main.css";
import CategoriesList from "../components/Blog/Categories.jsx";
import PostsList from "../components/Blog/Posts.jsx";
import FloatButton from "../components/FloatButton";

// markup
class BlogPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      category: null
    };
  }

  componentDidMount(){
    this.setCategory();
  }

  setCategory(){
    let search = window.location.search;
    let params = new URLSearchParams(search);
    this.setState({ 
      category: params.get("category"),
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      // let get_pararms = () => new URLSearchParams(search), [search];
      this.setCategory();
    }
  }

  render() {
    let {category} = this.state;
    return (
      <main style={pageStyles}>
        <FloatButton />
        <Helmet>
          <meta charSet="utf-8" />
          <title>Blog</title>
          <meta
            name="keywords"
            content="impresa di pulizie milano, impresa di pulizie milano prezzi, impresa pulizie costi milano, impresa di pulizie cesano boscone, impresa di sanificazione, impresa di pulizie lodi"
          />
        </Helmet>
        <Navbar />
        <DownbarS></DownbarS>
        <Banner pageName="Blog" />
        <section className="blog-section pt-130">
          <div className="container">
            <div className="row">
              <PostsList category={category}></PostsList>
              <div className="col-xl-4 col-lg-5">
                <div className="sidebar-wrapper">
                  <CategoriesList></CategoriesList>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer></Footer>
      </main>
    );
  }
}

export default BlogPage;
